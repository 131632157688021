import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, List, ListItemText, ListItemButton, ListItemIcon, IconButton } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import MenuIcon from '@mui/icons-material/Menu';
//import DraftsIcon from '@mui/icons-material/Drafts';

const LateralMenu = () => {
    const navigate = useNavigate();
    //const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [open, setOpen] = React.useState(false);

    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleToggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <>
            <IconButton onClick={handleToggleDrawer}>
                <MenuIcon />
            </IconButton>
            <Drawer
                variant="temporary"
                open={open}
                onClose={handleToggleDrawer}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: open ? 240 : 60,
                        backgroundColor: '#fff', 
                        color: '#000',
                        transition: 'width 0.3s',
                    },
                    '& .MuiListItemIcon-root': {
                        color: '#000', 
                    }
                }} 
            >
                <List>

                    <ListItemButton
                        onClick={() => handleNavigation('/investigaciones')}
                    >
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Investigaciones" />
                    </ListItemButton>

                    <ListItemButton
                        onClick={() => handleNavigation('/articulos')}
                    >
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Articulos" />
                    </ListItemButton>

                    <ListItemButton
                        onClick={() => handleNavigation('/ideas')}
                    >
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Ideas" />
                    </ListItemButton>

                    <ListItemButton
                        onClick={() => handleNavigation('/tags')}
                    >
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tags" />
                    </ListItemButton>
                </List>
            </Drawer >
        </>
    );
};

export default LateralMenu;
