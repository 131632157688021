import React, { useState } from 'react';
import {
    Container,
    TextField,
    Button,
    DialogContent,
    DialogActions,
    Snackbar,
    Alert,
    Grid,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography
} from '@mui/material';

function ArticuloForm({ initialData = {}, onSubmit, onCancel }) {
    const [articuloData, setArticuloData] = useState({
        titulo: initialData.titulo || '',
        autor: initialData.autor || '',
        link: initialData.link || '',
        bibtext: initialData.bibtext || '',
        abstract: initialData.abstract || '',
        ruta_pdf: initialData.ruta_pdf || '',
        doi: initialData.doi || '',
        tipo_publicacion: initialData.tipo_publicacion || '',
        nombre_publicacion: initialData.nombre_publicacion || '',
        issn_isbn: initialData.issn_isbn || '',
        volumen: initialData.volumen || '',
        numero: initialData.numero || '',
        paginas: initialData.paginas || '',
        fecha_publicacion: initialData.fecha_publicacion || '',
        palabras_clave: initialData.palabras_clave || '',
        idioma: initialData.idioma || '',
        cita_apa: initialData.cita_apa || '',
        estado_revision: initialData.estado_revision || ''
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [showMore, setShowMore] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setArticuloData({ ...articuloData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setArticuloData({ ...articuloData, ruta_pdf: file });
        }
    };

    const validateForm = () => {
        if (!articuloData.titulo) {
            setErrorMessage("El título y el autor son campos obligatorios.");
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            onSubmit(articuloData);
        }
    };

    return (
        <Container maxWidth="md" sx={{ maxHeight: '500px', overflowY: 'auto' }}>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* Campos Obligatorios */}
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Título"
                            type="text"
                            fullWidth
                            name="titulo"
                            value={articuloData.titulo}
                            onChange={handleInputChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            label="Abstract"
                            type="text"
                            fullWidth
                            multiline
                            rows={4}
                            name="abstract"
                            value={articuloData.abstract}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Importancia</InputLabel>
                            <Select
                                name="importancia"
                                value={articuloData.importancia}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="very-high">Very High</MenuItem>
                                <MenuItem value="high">High</MenuItem>
                                <MenuItem value="medium">Medium</MenuItem>                                
                                <MenuItem value="low">Low</MenuItem>
                                <MenuItem value="very-low">Very Low</MenuItem> 
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            onClick={() => setShowMore(!showMore)}
                            variant="outlined"
                            color="primary"
                        >
                            {showMore ? 'Mostrar menos' : 'Mostrar más'}
                        </Button>
                    </Grid>

                    {/* Campos Opcionales (se muestran solo si showMore es true) */}
                    {showMore && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Palabras clave"
                                    type="text"
                                    fullWidth
                                    name="palabras_clave"
                                    value={articuloData.palabras_clave}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Link"
                                    type="text"
                                    fullWidth
                                    name="link"
                                    value={articuloData.link}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="BibTeX"
                                    type="text"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    name="bibtext"
                                    value={articuloData.bibtext}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Autor"
                                    type="text"
                                    fullWidth
                                    name="autor"
                                    value={articuloData.autor}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    component="label"
                                >
                                    Cargar PDF
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(e) => setArticuloData({ ...articuloData, ruta_pdf: e.target.files[0] })}
                                    />
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="DOI"
                                    type="text"
                                    fullWidth
                                    name="doi"
                                    value={articuloData.doi}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel>Tipo de Publicación</InputLabel>
                                    <Select
                                        name="tipo_publicacion"
                                        value={articuloData.tipo_publicacion}
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value="Survey">Survey</MenuItem>
                                        <MenuItem value="Paper">Paper</MenuItem>
                                        <MenuItem value="Case Study">Case Study</MenuItem>
                                        {/* Agregar más opciones según sea necesario */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="dense"
                                    label="Nombre de la Publicación"
                                    type="text"
                                    fullWidth
                                    name="nombre_publicacion"
                                    value={articuloData.nombre_publicacion}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="dense"
                                    label="ISSN/ISBN"
                                    type="text"
                                    fullWidth
                                    name="issn_isbn"
                                    value={articuloData.issn_isbn}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    margin="dense"
                                    label="Volumen"
                                    type="text"
                                    fullWidth
                                    name="volumen"
                                    value={articuloData.volumen}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    margin="dense"
                                    label="Número"
                                    type="text"
                                    fullWidth
                                    name="numero"
                                    value={articuloData.numero}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="dense"
                                    label="Páginas"
                                    type="text"
                                    fullWidth
                                    name="paginas"
                                    value={articuloData.paginas}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="dense"
                                    label="Fecha de Publicación"
                                    type="date"
                                    fullWidth
                                    name="fecha_publicacion"
                                    value={articuloData.fecha_publicacion}
                                    onChange={handleInputChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel>Idioma</InputLabel>
                                    <Select
                                        name="idioma"
                                        value={articuloData.idioma}
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value="Español">Español</MenuItem>
                                        <MenuItem value="Inglés">Inglés</MenuItem>
                                        <MenuItem value="Portugués">Portugués</MenuItem>
                                        {/* Agregar más idiomas según sea necesario */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Cita APA"
                                    type="text"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    name="cita_apa"
                                    value={articuloData.cita_apa}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Estado de Revisión"
                                    type="text"
                                    fullWidth
                                    name="estado_revision"
                                    value={articuloData.estado_revision}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    {initialData.titulo ? 'Actualizar' : 'Crear'}
                </Button>
            </DialogActions>
        </Container>
    );
}

export default ArticuloForm;
